/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-06-24",
    versionChannel: "nightly",
    buildDate: "2024-06-24T00:06:34.251Z",
    commitHash: "04dfd1f22f50cce27dfcf7e6235e7244e9582524",
};
